#BigMilesStoneMainContainer{
    height: 100%;
}

#dotMainContainer{
    position: relative;
    height: 108px;
    padding-top: 30px;
    overflow: hidden;
    float: left;
    scroll-behavior: smooth;

}
#dotBodyContainer{
  
    float: left;
    height: 100%;  
   
}

.dotContainer{
    position: relative;
    float: left;
    height: 40px;
    height: 100%;
}

.dot{
    width: 40px;
    height:40px;
    border-radius: 50%;
    float:left;
    border: 2px solid #bfbbbb;
}
.selectedDot{
    background-color: #22b05b;;
    border: 3px solid #22b05b;
    box-shadow: 0px 0px 10px 3px #22b05b;
}
.squar{
    width: 30px;
    height:30px;
    margin-top: 35px;
    float:left;
    border: 3px solid #22b05b;
border-radius: 50%;
}

.line{
    height:3px;
    width: 80px;
    background-color: #22b05b;
    float:left;
    margin: 19px 0px;
}

.verticalLine{
    height:20px;
    background-color: #22b05b;
    margin: -7px 7px;
    height: 30px;
    width:5px
}
.title{
    position: absolute;
    font-size: 10px;
    bottom: 0;
    margin: auto;
    right: 0;
    left: 0;
    width: fit-content;
  color: #bfbbbb;
}
.selectedTitle{
  font-weight: bold;
  font-size: 12px;
  color: #9e9e9e;
}

.dotContainer:hover{
    font-weight: bold;
}

.dotContainer:hover .dot {
    font-weight: bold;
    box-shadow: 0px 0px 10px 3px #bfbbbb;
}

.imgMilIcon{
    height: 40px;
    width: 40px;
    margin: -2px 0px 0px -2px;
}

/* .imgMilNextFor:hover{
    box-shadow: 0px 0px 10px 3px #22b05b;
} */

.imgMilNextFor{
    height: 20px;
    width: 20px;
}

.selectedImg{
    filter: brightness(0) invert(1);
}

.greyFilter{
    filter: grayscale(1);
}

#BigMilesStoneMainContantContainer{
    margin-top: 40px ;
    /* height: 400px; */
}
@media (max-width: 768px) {
#BigMilesStoneMainContantContainer{
    height: initial
  }
  #BigMilesStoneMainContantContainerText> div,
  #BigMilesStoneMainContantContainerImg> div{
    padding:  20px 0 20px 0;
    }
    
}
  

#BigMilesStoneMainContantContainer .row{
height: 100%;
}

#BigMilesStoneMainContantContainerImg,
#BigMilesStoneMainContantContainerText{
    /* border: 1px solid #bfbbbb; */
height: 100%;
    overflow: auto;
}

#BigMilesStoneMainContantContainerImg> div{
padding: 20px;
height: 100%;
}

#BigMilesStoneMainContantContainerImg img{
    /* height: 358px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; */
    /* height: 100%; */
    height: auto;
    width: 100%;
object-fit: cover

}

#BigMilesStoneMainContantContainerText> div{
padding: 40px
}